<template>
  <div class="repaymen-detail">
    <div class="top-block">
      <p class="p1">{{ repaymentDetail.cudrstomerName }}</p>
      <p class="p2">
        {{ repaymentDetail.badrnkName }}（{{ repaymentDetail.badrnkAccount }}）
      </p>
      <p class="p3">Jumlah pinjaman sudah ditransfer ke rekening bank ini</p>
    </div>
    <div class="detail-list">
      <!-- 借款金额 -->
      <div class="info">
        <p>Nominal Pinjaman</p>
        <p>Rp{{ formatNumberWithCommas(repaymentDetail.lodranAmount) }}</p>
      </div>
      <!-- 贷款日期 -->
      <div class="info">
        <p>Waktu Pinjaman</p>
        <p>{{ repaymentDetail.ardrrivalTime }}</p>
      </div>
      <!-- 还款日期 -->
      <div class="info">
        <p>Waktu Pembayaran</p>
        <p>{{ repaymentDetail.redrpaymentTime }}</p>
      </div>

      <!-- 贷款期限 -->
      <div class="info">
        <p>Tenor Pinjaman</p>
        <p>{{ repaymentDetail.tidrmers }}</p>
      </div>
      <!-- 逾期天数 -->
      <div class="info">
        <p>Jumlah Hari Keterlambatan</p>
        <p>{{ repaymentDetail.ovdrerdueDay }} Hari</p>
      </div>
      <!-- 逾期罚息 -->
      <div class="info">
        <p>Denda Keterlambatan</p>
        <p>
          Rp{{
            formatNumberWithCommas(repaymentDetail.ovdrerdueInterestpenalty)
          }}
        </p>
      </div>
      <!-- 逾期违约金 -->
      <div class="info last">
        <p>Biaya Penundaan Keterlambatan</p>
        <p>
          Rp{{
            formatNumberWithCommas(repaymentDetail.ovdrerdueLiquidateddamages)
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: 'RepaymenDetail',
  data () {
    return {
      repaymentDetail: {}
    }
  },
  created () {
    this.getRepaymentDetail()
  },
  methods: {
    getRepaymentDetail () {
      request.post('homeDRInterface')
        .then(res => {
          this.repaymentDetail = res.bidrllDetails
        })
    }
  }
}
</script>

<style scoped lang="less">
.top-block {
  margin: 20px 15px;
  margin-top: 0;
  padding: 30px 15px;
  background: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 26px;
  color: #333333;
  .p1 {
    margin-bottom: 12px;
  }
  .p2 {
    font-weight: 600;
    margin-bottom: 12px;
  }
  .p3 {
    color: #999999;
  }
}

.detail-list {
  margin: 20px 15px;
  margin-bottom: 0;
  padding-bottom: 40px;
  background: #fff;
  padding: 0 30px;
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 94px;
    font-weight: 400;
    font-size: 26px;
    color: #333333;
    border-bottom: 2px solid #a0a0a0;
  }
  .info:last-child {
    border: none;
  }
}
</style>
